
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body, html {
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  padding-bottom: 40px;
}

a {
  color: #00873e;
}

.copy-buttons {
  font-size: 15px;
}
.copy-buttons h3 {
  text-align: center;
}

.col.active {
  border: 1px solid #00873e;
  border-radius: 20px;
}

.active .clicked {
  background-color: #88ccb0;
}

.schedule-container {
  max-width: 1174px;
}
.nutritional-table-holder {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
table.nutrition-table td {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

*,
::after,
::before {
  box-sizing: border-box;
}